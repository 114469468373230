import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import { LoginData } from './model'
import { LoginUser} from './LoginRequest'
import { useNavigate } from "react-router-dom";
import logo from '../../assets/images/airmart-logo.jpeg';
import { useEffect } from 'react';


const Login: React.FC = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const key = 'updatable';

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      navigate('/')
    }
  },[])

  const onFinish = async (values: LoginData) => {
     messageApi.open({
      key,
      type: 'loading',
      content: 'Login...',
    });
    const loginData = await LoginUser(values);
    if (loginData.token) {
       messageApi.open({
        key,
        type: 'success',
        content: 'Login Successful!',
        duration: 2,
      });
      localStorage.setItem('token', loginData.token)
      setTimeout(() => { 
        navigate('/');
      }, 500); 
    } else {
        messageApi.open({
        key,
        type: 'error',
        content: 'Something Went Wrong!',
        duration: 2,
      });
    }
  }

  return (
    <div className="login-page">
      <div className="login-details">
        <div className="login-form">
          <div className="airmart-logo"><img src = {logo} alt="Airmart Logo"/></div>
          <h5 className='login-headline'>Login to AirMart</h5>
           {contextHolder}
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              rules={[{ required: true, message: 'Please enter your Username!' }]}
            >
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please enter your Password!' }]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item className='login-button'>
              <Button type="primary" htmlType="submit" className="login-form-button">
                Log in
              </Button>
            </Form.Item>
         </Form>
        </div>

      </div>
    </div>
  )
}
export default Login