import { Layout } from "antd"
import { Content, Header } from "antd/es/layout/layout"
import logo from '../../assets/images/airmart-transperent.png'
import { useNavigate } from "react-router-dom";

const BasicLayout: React.FC<{ children: React.ReactNode }> = (props) => {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  }
  return (
     <Layout>
      <Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
      <div className="header-logo" >
        <img src={logo} alt="airmart-logo" />
        </div>
        <div onClick={() => logout() } className="logout">Logout</div>
      </Header>
      <Content>
        {props.children}
      </Content>
      </Layout>
  )
}
export default BasicLayout