import  { useEffect, useRef, useState } from "react"
import { detailAnalyticsData } from "./DetailAnalyticsRequest";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import dayjs from 'dayjs';
import { Bar } from 'react-chartjs-2';
import { Row,Col, Spin, Card, DatePicker } from "antd";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import type { RangePickerProps } from 'antd/es/date-picker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const { RangePicker } = DatePicker;
const DataAnalytics = () => {
  const componentRef = useRef(null);

  const navigate = useNavigate();
  const dateFormat = 'MM/DD/YYYY';
  const [data, setData] = useState({})
  const [dates, setDates] = useState<string[]>([]);
  const [pageView, setPageView] = useState<number[]>([])
  const [downloadSheet, setDownloadSheet] = useState<number[]>([])
  const [inquiryData, setInquiryData] = useState<number[]>([])
  const [pageName, setPageName] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [totalCounts, setTotalCounts] = useState<{ pageViewCount: number, downloadBtnCount: number, inquiryBtnCount: number }>({
    pageViewCount: 0,
    downloadBtnCount: 0,
    inquiryBtnCount:0
  })
  const [rangeDates, setRangeDates] = useState<{start_date:string, end_date:string}>();


  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
   const siteUrl = window.location.search;
    const urlParams = new URLSearchParams(siteUrl);
    const currentDate = new Date();
    const sixDaysAgo = new Date();
    sixDaysAgo.setDate(sixDaysAgo.getDate() - 6);

    const currentDateString = formatMonthDate(currentDate);
    const sixDaysAgoDateString = formatMonthDate(sixDaysAgo);
    const lastDay = formatYearDate(currentDate);
    const firstDay = formatYearDate(sixDaysAgo)   
    setRangeDates({ start_date: sixDaysAgoDateString, end_date: currentDateString })
    const data = {
      start_date: firstDay,
      end_date: lastDay,
      daywise: true,
      pagepath: urlParams.get('pageLink') || ""
    }
    getDayWiseAnalyticsData(data)
  }

  const formatMonthDate = (date:Date) =>  {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
  }

  const formatYearDate = (date:Date) =>  {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  }

  const getDayWiseAnalyticsData = async (data: { start_date: string, end_date: string, daywise: boolean, pagepath: string  }) => {
    let resData
    if (localStorage.getItem(data.start_date + data.end_date + data.pagepath)) {
      resData = JSON.parse(localStorage.getItem(data.start_date + data.end_date + data.pagepath) || '')
    } else {
      resData = await detailAnalyticsData(data)  
      localStorage.setItem(data.start_date + data.end_date+ data.pagepath, JSON.stringify(resData));
    }
    if (resData && Object.keys(resData).length > 0) {
      setIsLoading(false)
    }
      const datesArray: string[] = [];
      const pageViewsArray: number[] = [];
      const airmartDownloadSpecsheet: number[] = []
      const airCraftInquiryBtn: number[] = []

      for (const date in resData) {
        if (resData.hasOwnProperty(date)) {
          datesArray.push(formatDate(date));
          setDates(datesArray)
          const pageView = resData[date][0]?.page_view || 0;
          setPageName(resData[date][0]?.title);
          const airmartDownloadView = resData[date][0]?.['airmart-download-specsheet'] || 0;
          const airCraftInquiryData = resData[date][0]?.['aircraft-inquiry-btn'] || 0;
          airCraftInquiryBtn.push(parseInt(airCraftInquiryData, 10));
          airmartDownloadSpecsheet.push(parseInt(airmartDownloadView, 10));
          pageViewsArray.push(parseInt(pageView, 10));
          setPageView(pageViewsArray);
          setDownloadSheet(airmartDownloadSpecsheet)
          setInquiryData(airCraftInquiryBtn)
        }
      }
      const totalPageCount = pageViewsArray.reduce((acc, current) => current + acc, 0)
      const totalDownloadCount = airmartDownloadSpecsheet.reduce((acc,current) => current + acc,0)
      const totalInquiryCount = airCraftInquiryBtn.reduce((acc, current) => current + acc, 0)
      setTotalCounts({
        pageViewCount: totalPageCount | 0,
        downloadBtnCount: totalDownloadCount | 0,
        inquiryBtnCount: totalInquiryCount | 0
      })     
      setData(resData)
  }

  const changeDates = (values: any) => {
    const siteUrl = window.location.search;
    const urlParams = new URLSearchParams(siteUrl);
    if (values && values.length === 2) {
      const startDate = values[0]['$d'].toISOString().slice(0, 10)
      const endDate =  values[1]['$d'].toISOString().slice(0, 10)
      setRangeDates({ start_date: startDate, end_date: endDate })
      setIsLoading(true)
      if(startDate && endDate)
      getDayWiseAnalyticsData( {
        start_date: startDate,
        end_date: endDate,
        daywise: true,
        pagepath: urlParams.get('pageLink') || ""
      })
    }
  }
  
  const formatDate = (inputDate: string) => {
    const year = inputDate.slice(0, 4);
    const month = inputDate.slice(4, 6);
    const day = inputDate.slice(6, 8);
    return `${month}/${day}/${year}`;
  }

  const print =  useReactToPrint({
      content: () => componentRef.current
  });

const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  return current && current > dayjs().endOf('day');
};

  
  return (
    <>
    { data && Object.keys(data).length ?   
      <div style={{ margin: "20px" }}> 
        <div className="action-btn">
          <span className="back-btn" onClick={()=> {navigate(-1)}}>{ `< Back`}</span>   
          <span className="print-btn" onClick={()=> {print()}}>Print</span>
          </div>
        <div ref={componentRef}>
          <h2>{pageName}</h2>  
          <Row>
              <RangePicker
              disabledDate={disabledDate}
              defaultValue={[dayjs(rangeDates?.start_date, dateFormat), dayjs(rangeDates?.end_date, dateFormat)]}
              format={dateFormat}
              onChange={(values:any) => {
                changeDates(values)
              }}
              style={{ marginBottom: 10 }}
            />
          </Row>       
            <div className={isLoading ?  'chart-data loading': 'chart-data'}>
              {isLoading && <div className="detail-wrapper">
                <Spin tip="Loading" size="large">
                  <div className="content" />
                </Spin>
              </div>}
              <Row gutter={16}>
                <Col span={8}>
                  <Card  title="Total Page View " bordered={false}>{totalCounts.pageViewCount}</Card>
                </Col>
                <Col span={8}>
                  <Card  title="Total Airmart Download Specsheet" bordered={false}>{totalCounts.downloadBtnCount}</Card>
                </Col>
                <Col span={8}>
                  <Card  title="Total Aircraft Inquiry Button" bordered={false}>{totalCounts.inquiryBtnCount}</Card>
                </Col>
              </Row>
              <Row align="middle" style={{ marginTop: "20px", justifyContent: "center" }}>
                <Col span={20} >
                  <Card  title="Page View Analytics" bordered={false}>
                    <div style={{ height: "400px" }}>
                      <Bar options={{ responsive: true, maintainAspectRatio: false }}
                        data={
                          {
                            labels: dates,
                            datasets: [{
                              label: `Page View`,
                              data: pageView,
                              borderColor: "rgb(56, 82, 115)",
                              backgroundColor: "rgba(56, 82, 115)",
                              barPercentage: 0.5,
                              barThickness: 30,
                            }]
                          }}>
                      </Bar>
                    </div>
                  
                  </Card>
                </Col>
              </Row>
              <Row style={{ marginTop: "20px", justifyContent: "center" }}>
                <Col span={20}>
                  <Card  title="Airmart Download Specsheet Analytics" bordered={false}>
                    <div style={{ height: "400px" }}>
                      <Bar options={{ responsive: true, maintainAspectRatio: false }}
                        data={
                          {
                            labels: dates,
                            datasets: [{
                              label: `Aircraft Download Specsheet`,
                              data: downloadSheet,
                              borderColor: "rgb(56, 82, 115)",
                              backgroundColor: "rgba(56, 82, 115)",
                              barPercentage: 0.5,
                              barThickness: 30,
                            }]
                          }}>
              
                      </Bar>
                    </div>
                          
                  </Card>
                </Col>
              </Row>
              <Row style={{ marginTop: "20px", justifyContent: "center" }}>
                <Col span={20}>
                  <Card  title="Aircraft Inquiry Button Analytics" bordered={false}>
                    <div style={{ height: "400px" }}>
                      <Bar options={{ responsive: true, maintainAspectRatio: false }}
                        data={
                          {
                            labels: dates,
                            datasets: [{
                              label: `Aircraft Inquiry Btn`,
                              data: inquiryData,
                              borderColor: "rgb(56, 82, 115)",
                              barThickness: 50,
                              backgroundColor: "rgba(56, 82, 115)",
                            }]
                          }}>
                      </Bar>
                    </div>
                  
                  </Card>
                </Col>
              </Row>
            </div>
        </div>
      </div> 
      :
      <div className="loading-wrapper">
        <Spin tip="We are pulling data in real time, This can take some time, please have patience" size="large">
          <div className="content" />
        </Spin>
      </div>
    }
  </>
  )
      
}

export default DataAnalytics