import axios from "axios";
export const baseURL = 'https://stg-airmart-staging.kinsta.cloud/wp-json';

axios.interceptors.request.use(
  (config) => {
    // ** Get token from localStorage
    const accessToken = localStorage.getItem("token");

    // ** If token is present add it to request's Authorization Header
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
      // window.location.reload();
    }
    throw error;
  }
);

export const axiosPost = (
  url,
  data = {},
  params = {},
  shouldProcess = false,
  options = {}
) => {
  return axios
    .post(baseURL + url, data, {
      params,
      ...options,
    })
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return res;
    })
    .catch((error) => {
      return {
        error: error.response?.data?.message?.text
          ? error.response?.data?.message?.text
          : "Error while processing",
        errorData: error.response?.data?.message?.errors
          ? error.response?.data?.message?.errors
          : error.response?.data?.message
          ? error.response?.data?.message
          : [],
      };
    });
};

export const axiosGet = (url, params, shouldProcess = false) => {
  return axios
    .get(baseURL + url, { params })
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return res;
    })
    .catch((error) => {
      return {
        error: error.response?.data?.message?.text
          ? error.response?.data?.message?.text
          : "Error while processing",
      };
    });
};

export const axiosPut = (
  url,
  data = {},
  params = {},
  shouldProcess = false
) => {
  return axios
    .put(baseURL + url, data, { params })
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return res;
    })
    .catch((error) => {
      return {
        error: error.response?.data?.message?.text
          ? error.response?.data?.message?.text
          : "Error while processing",
      };
    });
};

export const axiosPatch = (url, data = {}, params, shouldProcess = false) => {
  return axios
    .patch(baseURL + url, data, { params })
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return res;
    })
    .catch((error) => {
      return {
        error: error.response?.data?.message?.text
          ? error.response?.data?.message?.text
          : "Error while processing",
      };
    });
};

export const axiosDelete = (url, params, shouldProcess = false) => {
  return axios
    .delete(baseURL + url, { params })
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return res;
    })
    .catch((error) => {
      return {
        error: error.response?.data?.message?.text
          ? error.response?.data?.message?.text
          : "Error while processing",
      };
    });
};

export default axios;
