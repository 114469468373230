
import './App.css';
import Login from './pages/Login/Login';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements

} from "react-router-dom";
import Home from './pages/Home/Home';
import PrivateRoute from './router/PrivateRouter';
import DataAnalytics from './pages/DetailAnalytics/DetailAnalytics';

function App() {

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path='/' element={<PrivateRoute Component={Home} />} />
      <Route path='/detail-view' element={<PrivateRoute Component={DataAnalytics}/>}  /> 
      <Route path='/login' element={<Login />} /> 
    </>
  )
);
 return <RouterProvider router={router} />;

}

export default App;

