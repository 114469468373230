import { useEffect, useRef } from "react"
import { analyticsData } from "./HomeRequest"
import React, { useState } from 'react';
import { DatePicker, Layout, Spin, Table, theme, Input, Row, Col } from 'antd';
import dayjs from 'dayjs';
import { Content, Header } from "antd/es/layout/layout";
import type {  ColumnsType } from 'antd/es/table';
import { Link } from "react-router-dom";
import type { RangePickerProps } from 'antd/es/date-picker';

const { RangePicker } = DatePicker;
interface DataType {
  key: React.Key;
  title: string;
  name: string;
  page_view: string;
  publish_date: string;
  'airmart-download-specsheet': string,
  'aircraft-inquiry-btn': string;
}

const Home = () => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [dates, setDates] = useState<{start_date:string, end_date:string}>();
  const [data, setData] = useState<DataType[]>([])
  const [filterData, setFilterData] = useState<DataType[]>([])
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const dateFormat = 'MM/DD/YYYY';


  const columns:ColumnsType<DataType> = [   
    {
      title: 'Link',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ['descend', 'ascend'],
      render: (value: string) => <Link to={`detail-view?pageLink=${value}`} >{value}</Link>,
    },
    {
      title: 'Page Name',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a.title.length - b.title.length,
      sortDirections: ['descend', 'ascend'],
    },
      {
      title: 'Publish Date',
      dataIndex: 'publish_date',
      key: 'publish_date',
      sorter: { compare: (a:any, b:any) => {
      a = a.publish_date.toLowerCase()
      b = b.publish_date.toLowerCase()
      return a > b ? -1 : b > a ? 1 : 0
      },
      },
      sortDirections: ['descend', 'ascend'],
      render: (value: string) => { return <span>{formatePublishDate(value)}</span> }
    },
    {
      title: 'Page View',
      dataIndex: 'page_view',
      key: 'page_view',
      sorter: (a, b) => parseInt(a.page_view) - parseInt(b.page_view),
      sortDirections: ['descend', 'ascend']    
    },
    {
      title: 'airmart-download-specsheet',
      dataIndex: 'airmart-download-specsheet',
      key: 'airmart-download-specsheet',
      sorter: (a, b) => parseInt(a['airmart-download-specsheet']) -parseInt(b['airmart-download-specsheet']),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'aircraft-inquiry-btn',
      dataIndex: 'aircraft-inquiry-btn',
      key: 'aircraft-inquiry-btn',
      sorter: (a, b) => parseInt(a['aircraft-inquiry-btn']) -parseInt(b['aircraft-inquiry-btn']),
      sortDirections: ['descend', 'ascend']
    }
  ]

  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    const currentDate = new Date();
    const sixDaysAgo = new Date();
    sixDaysAgo.setDate(sixDaysAgo.getDate() - 6);

    const currentDateString = formatMonthDate(currentDate);
    const sixDaysAgoDateString = formatMonthDate(sixDaysAgo);
    const lastDay = formatYearDate(currentDate);
    const firstDay = formatYearDate(sixDaysAgo)
    setDates({ start_date: sixDaysAgoDateString, end_date: currentDateString })
    const data = {
      start_date: firstDay,
      end_date: lastDay
    }
    getAnalyticsData(data)
  }
  
  const formatMonthDate = (date:Date) =>  {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
  }
   const formatYearDate = (date:Date) =>  {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  }
  const formatePublishDate = (dateString: string) => {
    const parts = dateString.split('-');
    const formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`;
    return formattedDate;
  }


  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > dayjs().endOf('day');
  };
  const getAnalyticsData = async (data: { start_date: string, end_date: string }) => {
    if (localStorage.getItem(data.start_date + data.end_date)) {
      const localData = JSON.parse(localStorage.getItem(data.start_date + data.end_date) || '')
      setData(localData)
    } else {
      const resData = await analyticsData(data) 
      setData(resData)
      localStorage.setItem(data.start_date + data.end_date, JSON.stringify(resData));
    }
  }
  const changeDates = (values: any) => {
    setSearchTerm('')
    setFilterData([])
    if (values && values.length === 2) {
      const startDate = values[0]['$d'].toISOString().slice(0, 10)
      const endDate = values[1]['$d'].toISOString().slice(0, 10)
      setDates({ start_date: startDate, end_date: endDate })
      getAnalyticsData( {
      start_date: startDate,
      end_date: endDate
    })
    }
  }

  const search = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value.toLowerCase()
    setSearchTerm(searchTerm);
    filterSearchData(searchTerm);
  
  };

  const filterSearchData = (searchTerm: string) => {
    const filteredResults = data.filter((aircraft) =>   
        aircraft.title.toLowerCase().includes(searchTerm) ||
        aircraft.name.toLowerCase().includes(searchTerm) ||
        aircraft.page_view.includes(searchTerm) ||
        (aircraft['airmart-download-specsheet'] && aircraft['airmart-download-specsheet'].includes(searchTerm)) ||
        (aircraft['aircraft-inquiry-btn'] && aircraft['aircraft-inquiry-btn'].includes(searchTerm))
      );
      setFilterData(filteredResults)
  }

  
  return (
    <Layout>
      <Header
        style={{
          paddingLeft: 24,
          fontSize: 20,
          background: colorBgContainer,
          fontWeight: 600,
        }}
      >
      <span>Airmart Analytics</span>
      </Header>
      <Content
        style={{
          margin: "16px",
        }}
      >
        <div
          style={{
            padding: 24,
            minHeight: 360,
            background: colorBgContainer,
          }}
        >
          {data && data.length > 0 ?
          <>
            <Row gutter={16}> 
              <Col span={12}>
                <Input.Search
                  value={searchTerm}
                  placeholder="Search..."
                  onChange={(value) => {search(value)}}
                    />
              </Col>
              <Col span={12}>
                <RangePicker
                  disabledDate={disabledDate}
                  defaultValue={[dayjs(dates?.start_date, dateFormat), dayjs(dates?.end_date, dateFormat)]}
                  format={dateFormat}
                  onChange={(values:any) => {
                    changeDates(values)
                  }}
                  style={{ marginBottom: 10 }}
              />
              </Col>   
            </Row>  
            <Table
            columns={columns}
            dataSource={filterData && filterData.length > 0  ? filterData : data}
            rowKey={(r) => r.name}
            scroll={{  y: 500 }}
            />
            </>
          :
          <div className="loading-wrapper">
            <Spin tip="We are pulling data in real time, This can take some time, please have patience" size="large">
              <div className="content" />
            </Spin>
          </div>
        } 
        </div>
      </Content>
    </Layout>      
  );

}
export default Home